import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Landing.module.css";
import building from "../building/building.jpg";

const Landing = () => {
  const navigate = useNavigate();

  return (
    <Container fluid>
      <Row>
        <Col
          md={6}
          className="d-flex flex-column justify-content-center align-items-center vh-100"
        >
          <h1 className="mb-4">Welcome to Intern Tracker T-Works</h1>
          <h3 className="mb-4">Track and Manage Interns Efficiently</h3>
          <ul className="text-left mb-4">
            <li className="mb-2">
              Monitor intern progress and performance in real-time.
            </li>
            <li className="mb-2">Assign and manage tasks effortlessly.</li>
            <li className="mb-2">
              Seamless communication between admins and interns.
            </li>
            <li className="mb-2">Intuitive and user-friendly interface.</li>
            <li className="mb-2">Detailed reports and analytics.</li>
            <li className="mb-2">
              Customizable dashboards to suit your needs.
            </li>
          </ul>
          <div
            className="d-flex justify-content-center"
            style={{ width: "100%" }}
          >
            <Button
              variant="primary"
              style={{ width: "150px", height: "50px" }}
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </div>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center p-0"
        >
          <img
            src={building}
            alt="Intern Tracker"
            className="img-fluid w-100 vh-100"
            style={{ objectFit: "cover" }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Landing;
