import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styles from "./customnav.module.css"; // import CSS module

function CustomNavbar() {
  const navigate = useNavigate();
  const name = localStorage.getItem("name") || "User";
  const role = localStorage.getItem("role") || "Intern";

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();

    // Delete all cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // Explicitly delete specific cookie if not deleted
    document.cookie =
      "connect.sid=;expires=" + new Date(0).toUTCString() + ";path=/";

    navigate("/");

    // Perform a hard refresh
    // window.location.reload(true);
  };

  const getGreeting = () => {
    let roleName = "Intern";
    if (role === "manager") {
      roleName = "Manager";
    } else if (role === "admin") {
      roleName = "Admin";
    }
    return ` Hi ${name} - ( ${roleName} )`;
  };

  const getHomeLink = () => {
    if (role === "manager") {
      return "/manager";
    } else if (role === "admin") {
      return "/admin";
    } else {
      return "/intern";
    }
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      fixed="top"
      className={styles.customNavbar}
    >
      <Navbar.Brand as={Link} to="/" className={styles.navbarLogo}>
        {getGreeting()}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className={styles.navbarLinks}>
          <Nav.Link as={Link} to={getHomeLink()}>
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/profile">
            Profile
          </Nav.Link>
          <Nav.Link
            href="https://forms.gle/iZqW7M1Le3mwFwtr7"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bug Report
          </Nav.Link>
          <Nav.Link as={Link} to="/tutorial">
            Tutorial
          </Nav.Link>
          <Button
            className={styles.logout}
            onClick={handleLogout}
            variant="danger"
          >
            Logout
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
