import Task from "../pages/Task/Task";
import Profile from "../pages/Profile/Profile";
import Admin from "../pages/Admin/Admin";

import Intern from "../pages/Intern/Intern";
import List from "../pages/List/List";
import AdminProfile from "../pages/AdminProfile/AdminProfile";
import Calender from "../pages/Calender/Calender";
import MyManager from "../pages/MyManager/MyManager";
import Manager from "../pages/Manager/Manager";
import Users from "../pages/Users/Users";
import MyInterns from "../pages/MyInterns/MyInterns";
import MyAdmin from "../pages/MyAdmin/MyAdmin";
import Add from "../pages/Add/Add";
import Tutorial from "../pages/Tutorial/Tutorial";

export const privateRoutes = [
  {
    name: "Intern",
    path: "/intern",
    element: <Intern />,
    roles: ["intern"],
  },
  {
    name: "Manager",
    path: "/manager",
    element: <Manager />,
    roles: ["manager"],
  },
  {
    name: "Add Task Manager",
    path: "/manager/task/:id",
    element: <Add />,
    roles: ["manager"],
  },
  {
    name: "Admin",
    path: "/admin",
    element: <Admin />,
    roles: ["admin"],
  },
  {
    name: "All Users",
    path: "/mytotaluserss",
    element: <Users />,
    roles: ["admin"],
  },
  {
    name: "All Interns",
    path: "/myinterns",
    element: <MyInterns />,
    roles: ["admin"],
  },
  {
    name: "All Managers",
    path: "/mymanagers",
    element: <MyManager />,
    roles: ["admin"],
  },
  {
    name: "All Admins",
    path: "/myadmins",
    element: <MyAdmin />,
    roles: ["admin"],
  },

  {
    name: "All Interns",
    path: "/list",
    element: <List />,
    roles: ["admin", "intern"],
  },
  {
    name: "Intern Data", // Updated route name for clarity
    path: "/interns/:id", // Updated path to match your API endpoint
    element: <Calender />,
    roles: ["admin", "intern"],
  },
  {
    name: "Admin Profile",
    path: "/admin-profile",
    element: <AdminProfile />,
    roles: ["admin", "intern"],
  },
  {
    name: "Profile",
    path: "/profile",
    element: <Profile />,
    roles: ["admin", "intern", "manager"],
  },
  {
    name: "Vudeo",
    path: "/tutorial",
    element: <Tutorial />,
    roles: ["admin", "intern", "manager"],
  },
  {
    name: "Task Detail", // Name the route descriptively
    path: "/intern/:id",
    element: <Task />,
    roles: ["intern"],
  },
];
