import React from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

const ConfettiComponent = ({ run }) => {
    const { width, height } = useWindowSize();
    return run ? <Confetti width={width} height={height} tweenDuration={4000} /> : null;
};

export default ConfettiComponent;
