import React, { useState, useEffect } from "react";
import {
  Form,
  FormControl,
  Button,
  Card,
  Container,
  Row,
  Col,
  Alert,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import axios from "../axiosconfig/axiosconfig";
import moment from "moment";
import ConfettiComponent from "../ConfettiComponent/ConfettiComponent";
import RichTextEditor from "react-rte";
import HtmlParser from "react-html-parser";
import styles from "./intern.module.css";

function Intern() {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [formValues, setFormValues] = useState({
    title: "",
    description: RichTextEditor.createEmptyValue(),
    startDate: "",
    endDate: "",
    attachments: [],
    isCompleted: false,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [editTaskId, setEditTaskId] = useState(null);
  const [deleteTaskId, setDeleteTaskId] = useState(null);
  const [confetti, setConfetti] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isModalVisible) {
      setFormValues({
        title: "",
        description: RichTextEditor.createEmptyValue(),
        startDate: "",
        endDate: "",
        attachments: [],
        isCompleted: false,
      });
    }
  }, [isModalVisible]);

  // Reset form values when the edit modal is shown

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get("/users");
      const sortedTasks = response.data.tasks.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setTasks(sortedTasks);
      setFilteredTasks(sortedTasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const handleAddTask = async () => {
    // Validation
    if (
      !formValues.title ||
      !formValues.description ||
      !formValues.startDate ||
      !formValues.endDate
    ) {
      setAlertMessage("All fields are required.");
      return;
    }

    const formData = new FormData();
    formData.append("title", formValues.title);
    formData.append("description", formValues.description.toString("html"));
    formData.append("startDate", formValues.startDate);
    formData.append("endDate", formValues.endDate);
    formValues.attachments.forEach((file) => {
      formData.append("attachments", file);
    });

    try {
      await axios.post("/tasks", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsModalVisible(false);
      fetchTasks();
      triggerConfetti();
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleDescriptionChange = (value) => {
    setFormValues({
      ...formValues,
      description: value,
    });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredTasks(
      query === ""
        ? tasks
        : tasks.filter(
          (task) =>
            task.title.toLowerCase().includes(query) ||
            task.description.toLowerCase().includes(query)
        )
    );
  };

  const handleEditTask = async () => {
    // Validation
    if (
      !formValues.title ||
      !formValues.description ||
      !formValues.startDate ||
      !formValues.endDate
    ) {
      setAlertMessage("All fields are required.");
      return;
    }

    const formData = new FormData();
    formValues.attachments.forEach((file) =>
      formData.append("attachments", file)
    );
    formData.append("title", formValues.title);
    formData.append("description", formValues.description.toString("html"));
    formData.append("startDate", formValues.startDate);
    formData.append("endDate", formValues.endDate);
    formData.append("isCompleted", formValues.isCompleted);

    try {
      await axios.put(`/tasks/${editTaskId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsEditModalVisible(false);
      fetchTasks();
      triggerConfetti();
    } catch (error) {
      console.error("Error editing task:", error);
    }
  };

  const handleEditButtonClick = (task) => {
    setEditTaskId(task._id);
    setFormValues({
      title: task.title,
      description: RichTextEditor.createValueFromString(
        task.description,
        "html"
      ),
      startDate: moment(task.startDate).format("YYYY-MM-DD"),
      endDate: moment(task.endDate).format("YYYY-MM-DD"),
      attachments: [],
      isCompleted: task.isCompleted,
    });
    setIsEditModalVisible(true);
  };

  const triggerConfetti = () => {
    setConfetti(true);
    setTimeout(() => setConfetti(false), 2000);
  };

  const handleDeleteTask = async () => {
    try {
      await axios.delete(`/user/task/${deleteTaskId}`);
      setIsDeleteModalVisible(false);
      fetchTasks();
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <CustomNavbar />
      <Container className={styles.customContainer}>
        <Form className={styles.customForm}>
          <FormControl
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <br></br>
          <Button variant="primary" onClick={() => setIsModalVisible(true)}>
            Add Task
          </Button>
          <br></br>
          <br></br>
        </Form>
        <Row>
          {filteredTasks.map((task) => (
            <Col
              key={task._id}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={styles.customCol}
            >
              <Card
                className={styles.customCard}
                onClick={() => navigate(`/intern/${task._id}`)}
              >
                <Card.Body className={styles.customCardBody}>
                  <Card.Title className={styles.customCardTitle}>
                    {task.title}
                  </Card.Title>
                  <Card.Text className={styles.customCardText}>
                    {HtmlParser(
                      task.description.length > 20
                        ? `${task.description.substring(0, 20)}...`
                        : task.description
                    )}
                  </Card.Text>
                  <Card.Text>
                    <small>
                      <span className="bold"><strong>Start Date: </strong></span>
                      {moment(task.startDate).format("Do MMM YYYY")}
                    </small>
                  </Card.Text>
                  <Card.Text>
                    <small>
                      <span className="bold"><strong>End Date: </strong></span>
                      {moment(task.endDate).format("Do MMM YYYY")}
                    </small>
                  </Card.Text>

                  <Card.Text>
                    <small>
                      <span className="bold"><strong>
                        Completed: </strong></span> {task.isCompleted ? '✅' : '❌'}
                    </small>
                  </Card.Text>
                  <Button
                    style={{ margin: "10px" }}
                    variant="warning"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditButtonClick(task);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    style={{ margin: "10px" }}
                    variant="danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteTaskId(task._id);
                      setIsDeleteModalVisible(true);
                    }}
                  >
                    Delete
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

      </Container>

      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertMessage && (
            <Alert
              variant="danger"
              onClose={() => setAlertMessage("")}
              dismissible
            >
              {alertMessage}
            </Alert>
          )}
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formValues.title}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <RichTextEditor
                value={formValues.description}
                onChange={handleDescriptionChange}
              />
            </Form.Group>
            <Form.Group controlId="formStartDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={formValues.startDate}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEndDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={formValues.endDate}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddTask}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isEditModalVisible}
        onHide={() => setIsEditModalVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertMessage && (
            <Alert
              variant="danger"
              onClose={() => setAlertMessage("")}
              dismissible
            >
              {alertMessage}
            </Alert>
          )}
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formValues.title}
                onChange={(e) =>
                  setFormValues({ ...formValues, title: e.target.value })
                }
                required
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <RichTextEditor
                value={formValues.description}
                onChange={(value) =>
                  setFormValues({ ...formValues, description: value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formStartDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={formValues.startDate}
                onChange={(e) =>
                  setFormValues({ ...formValues, startDate: e.target.value })
                }
                required
              />
            </Form.Group>
            <Form.Group controlId="formEndDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={formValues.endDate}
                onChange={(e) =>
                  setFormValues({ ...formValues, endDate: e.target.value })
                }
                required
              />
            </Form.Group>
            <br></br>
            <Form.Group controlId="formIsCompleted">
              <Form.Check
                type="checkbox"
                name="isCompleted"
                label="Is Completed"
                checked={formValues.isCompleted}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setIsEditModalVisible(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleEditTask}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isDeleteModalVisible}
        onHide={() => setIsDeleteModalVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this task?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setIsDeleteModalVisible(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteTask}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfettiComponent run={confetti} />
    </div>
  );
}

export default Intern;
