import React, { useEffect, useState } from "react";
import axios from "../axiosconfig/axiosconfig.js";
import CustomNavbar from "../CustomNavbar/CustomNavbar.js";
import styles from "./MyInterns.module.css";
import { Modal, Button, Form, Alert } from "react-bootstrap";

function MyInterns() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertVariant, setAlertVariant] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);

  const [managers, setManagers] = useState([]);

  const [newUser, setNewUser] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    role: "intern",
    designations: "",
    joiningDate: "",
    department: "adminfacilitiesmaintenance",
    profileImagePath: "",
    reportingManager: "",
  });

  const parseDateString = (dateString) => {
    if (!dateString) return "";
    if (dateString.includes("-")) return dateString; // Already in the correct format

    const [day, month, year] = dateString.split(" ");
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    const dayNumber = parseInt(day, 10);
    return `${year}-${monthMap[month]}-${
      dayNumber < 10 ? "0" + dayNumber : dayNumber
    }`;
  };

  let fetchUsers;
  useEffect(() => {
    fetchUsers = async () => {
      try {
        const response = await axios.get("/allusers");
        const sortedUsers = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setUsers(sortedUsers);
      } catch (error) {
        console.error("There was an error fetching the users!", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const response = await axios.get("/managers");
        setManagers(response.data);
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    };

    fetchManagers();
  }, []);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSort = (key) => {
    const sortedUsers = [...users].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setUsers(sortedUsers);
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate.replace(day, day + suffix(day));
  };

  const calculateTotalDays = (joiningDate) => {
    const joinDate = new Date(joiningDate);
    const today = new Date();
    const diffTime = Math.abs(today - joinDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const filteredUsers = users.filter(
    (user) =>
      (user.name.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())) &&
      user.role.toLowerCase() === "intern"
  );

  console.log(filteredUsers);

  function toTitleCase(department) {
    if (department === "adminfacilitiesmaintenance") {
      return "Admin Facilities Maintenance";
    } else if (department === "financeaccounting") {
      return "Finance Accounting";
    } else if (department === "operations") {
      return "Operation";
    } else if (department === "peoplecx") {
      return "People CX";
    } else if (department === "procurementstores") {
      return "Procurement Stores";
    } else if (department === "protoservices") {
      return "Proto Services";
    } else if (department === "productengineering") {
      return "Product Engineering";
    } else if (department === "software") {
      return "Software";
    } else if (department === "admin") {
      return "Admin";
    } else if (department === "ceo") {
      return "CEO";
    } else if (department === "manager") {
      return "Manager";
    } else if (department === "all") {
      return "All";
    } else if (department === "other") {
      return "Other";
    } else {
      return department;
    }
  }
  const handleModalChange = (event) => {
    const { name, value } = event.target;
    setNewUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (showAddModal) {
      setNewUser({
        name: "",
        phone: "",
        email: "",
        password: "",
        role: "admin",
        designations: "",
        joiningDate: "",
        department: "adminfacilitiesmaintenance",
        profileImagePath: "",
        reportingManager: "",
      });
    }
  }, [showAddModal]);

  const handleAddUser = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    if (
      !newUser.name ||
      !newUser.phone ||
      !newUser.email ||
      !newUser.password ||
      !newUser.designations
    ) {
      setAlertMessage("Please fill all required fields.");
      setAlertVariant("danger");
      setTimeout(() => setAlertMessage(null), 2000); // Alert for 2 seconds
      return; // Stop the function if validation fails
    }

    // Validate email and phone using regex
    if (!emailRegex.test(newUser.email)) {
      setAlertMessage("Invalid email format.");
      setAlertVariant("danger");
      setTimeout(() => setAlertMessage(null), 2000); // Alert for 2 seconds
      return; // Stop the function if email validation fails
    }
    if (!phoneRegex.test(newUser.phone)) {
      setAlertMessage(
        "Invalid phone number format. Phone number should be 10 digits."
      );
      setAlertVariant("danger");
      setTimeout(() => setAlertMessage(null), 2000); // Alert for 2 seconds
      return; // Stop the function if phone validation fails
    }

    try {
      let response = await axios.post("/users", newUser);
      console.log(response.data);

      setUsers([...users, response.data]); // Assuming API returns the added user
      setAlertMessage("User Added Successfully!");
      setAlertVariant("success");

      setTimeout(() => {
        setShowAddModal(false);
        setAlertMessage(null);
        setNewUser({
          name: "",
          phone: "",
          email: "",
          password: "",
          role: "admin",
          designations: "",
          joiningDate: "",
          department: "adminfacilitiesmaintenance",
          profileImagePath: "",
          reportingManager: "",
        });
      }, 2000); // Alert for 2 seconds
      refreshPage();
    } catch (error) {
      console.error("There was an error adding the user!", error);
      setAlertMessage(
        error.response ? error.response.data.message : "Error adding user."
      );
      setAlertVariant("danger");
      setTimeout(() => setAlertMessage(null), 2000); // Alert for 2 seconds
    }
  };

  const handleEditUser = async () => {
    // Ensure the date is in the correct format before sending it to the server
    const formattedUser = {
      ...newUser,
      joiningDate: newUser.joiningDate
        ? parseDateString(newUser.joiningDate)
        : "",
    };

    try {
      let response = await axios.put(
        `/editusers/${selectedUserId}`,
        formattedUser
      );
      console.log(response.data);

      setUsers(
        users.map((user) =>
          user._id === selectedUserId ? response.data : user
        )
      ); // Use `_id` if that's the key
      setAlertMessage("User Edited Successfully!");
      setAlertVariant("success");

      setTimeout(() => {
        setShowEditModal(false);
        setAlertMessage(null);
        setSelectedUserId(null);
        setNewUser({
          name: "",
          phone: "",
          email: "",
          password: "",
          role: "admin",
          designations: "",
          joiningDate: "",
          department: "adminfacilitiesmaintenance",
          profileImagePath: "",
          reportingManager: "",
        });
      }, 2000);
      refreshPage();
    } catch (error) {
      console.error("There was an error editing the user!", error);
      setAlertMessage(error.response.data.message);
      setAlertVariant("danger");
    }
  };

  const handleDeleteUser = async () => {
    try {
      await axios.delete(`/delusers/${selectedUserId}`);
      setUsers(users.filter((user) => user._id !== selectedUserId));
      setAlertMessage("User Deleted Successfully!");
      setAlertVariant("success");

      setTimeout(() => {
        setShowDeleteModal(false);
        setAlertMessage(null);
        setSelectedUserId(null);
        setSelectedUserName(null);
      }, 1000);
      refreshPage();
    } catch (error) {
      console.error("There was an error deleting the user!", error);
      setAlertMessage(error.response.data.message);
      setAlertVariant("danger");
    }
  };

  const handleShowDeleteModal = (user) => {
    setSelectedUserId(user._id);
    setSelectedUserName(user.name); // Assuming the user's name is stored in the 'name' field
    setShowDeleteModal(true);
  };

  const handleShowEditModal = (user) => {
    setSelectedUserId(user._id);
    setNewUser(user);
    setShowEditModal(true);
  };

  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <CustomNavbar />
      <div className={styles.usersContainer}>
        <h1>Interns</h1>
        <input
          type="text"
          placeholder="Search by name or email"
          value={search}
          onChange={handleSearchChange}
          className={styles.searchInput}
        />
        <Button variant="primary" onClick={() => setShowAddModal(true)}>
          Add Intern
        </Button>
        <table className={styles.usersTable}>
          <thead>
            <tr>
              <th onClick={() => handleSort("name")}>Name</th>
              <th onClick={() => handleSort("phone")}>Phone</th>
              <th onClick={() => handleSort("email")}>Email</th>
              <th onClick={() => handleSort("role")}>Role</th>
              <th onClick={() => handleSort("designations")}>Designation</th>
              <th onClick={() => handleSort("department")}>Department</th>
              <th onClick={() => handleSort("reportingManager")}>Manager</th>
              <th onClick={() => handleSort("joiningDate")}>Joining Date</th>
              <th>Total Days</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user._id}>
                <td>{user.name}</td>
                <td>{user.phone}</td>
                <td>{user.email}</td>
                <td>
                  {user.role === "intern"
                    ? "Intern"
                    : user.role === "manager"
                    ? "Manager"
                    : user.role === "admin"
                    ? "Admin"
                    : user.role}
                </td>
                <td>{user.designations}</td>
                <td>{toTitleCase(user.department)}</td>
                <td>{user.reportingManager}</td>
                <td>{formatDate(user.joiningDate)}</td>
                <td>{calculateTotalDays(user.joiningDate)} days</td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => handleShowEditModal(user)}
                    style={{ marginRight: "10px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleShowDeleteModal(user)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertMessage && <Alert variant={alertVariant}>{alertMessage}</Alert>}
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>
                Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                value={newUser.name}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formPhone">
              <Form.Label>
                Phone <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="phone"
                required
                value={newUser.phone}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formEmail">
              <Form.Label>
                Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                required
                value={newUser.email}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formPassword">
              <Form.Label>
                Password <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="password"
                name="password"
                required
                value={newUser.password}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formRole">
              <Form.Label>
                Role <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={newUser.role}
                onChange={handleModalChange}
              >
                <option value="intern">Intern</option>
              </Form.Control>
            </Form.Group>
            <br />
            <Form.Group controlId="formDesignations">
              <Form.Label>
                Designations <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="designations"
                required
                value={newUser.designations}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formJoiningDate">
              <Form.Label>
                Joining Date <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="joiningDate"
                value={parseDateString(newUser.joiningDate)}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formDepartment">
              <Form.Label>
                Department <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="department"
                value={newUser.department}
                onChange={handleModalChange}
              >
                <option value="adminfacilitiesmaintenance">
                  Admin Facilities & Maintenance
                </option>
                <option value="financeaccounting">Finance & Accounting</option>
                <option value="operations">Operations</option>
                <option value="peoplecx">People & CX</option>
                <option value="procurementstores">Procurement & Stores</option>
                <option value="protoservices">ProtoServices</option>
                <option value="productengineering">Product Engineering</option>
                <option value="software">Software</option>
                <option value="admin">Admin</option>
                <option value="ceo">CEO</option>
                <option value="manager">Manager</option>
                <option value="all">All</option>
                <option value="other">Other</option>
              </Form.Control>
            </Form.Group>
            <br></br>
            <Form.Group controlId="formName">
              <Form.Label>Manager</Form.Label>
              <Form.Control
                as="select"
                name="reportingManager"
                value={newUser.reportingManager}
                onChange={handleModalChange}
              >
                <option value="">Select Manager</option>
                {managers.map((manager) => (
                  <option key={manager._id} value={manager.name}>
                    {manager.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddUser}>
            Add User
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertMessage && <Alert variant={alertVariant}>{alertMessage}</Alert>}
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>
                Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                value={newUser.name}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formPhone">
              <Form.Label>
                Phone <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="phone"
                required
                value={newUser.phone}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formEmail">
              <Form.Label>
                Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                required
                value={newUser.email}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />

            <Form.Group controlId="formRole">
              <Form.Label>
                Role <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={newUser.role}
                onChange={handleModalChange}
              >
                <option value="intern">Intern</option>
              </Form.Control>
            </Form.Group>
            <br />
            <Form.Group controlId="formDesignations">
              <Form.Label>
                Designations <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="designations"
                required
                value={newUser.designations}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formJoiningDate">
              <Form.Label>
                Joining Date <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="joiningDate"
                value={newUser.joiningDate}
                onChange={handleModalChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formDepartment">
              <Form.Label>
                Department <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="department"
                value={newUser.department}
                onChange={handleModalChange}
              >
                <option value="adminfacilitiesmaintenance">
                  Admin Facilities & Maintenance
                </option>
                <option value="financeaccounting">Finance & Accounting</option>
                <option value="operations">Operations</option>
                <option value="peoplecx">People & CX</option>
                <option value="procurementstores">Procurement & Stores</option>
                <option value="protoservices">ProtoServices</option>
                <option value="productengineering">Product Engineering</option>
                <option value="software">Software</option>
                <option value="admin">Admin</option>
                <option value="ceo">CEO</option>
                <option value="manager">Manager</option>
                <option value="all">All</option>
                <option value="other">Other</option>
              </Form.Control>
            </Form.Group>
            <br></br>
            <Form.Group controlId="formName">
              <Form.Label>Manager</Form.Label>
              <Form.Control
                as="select"
                name="reportingManager"
                value={newUser.reportingManager}
                onChange={handleModalChange}
              >
                <option value="">Select Manager</option>
                {managers.map((manager) => (
                  <option key={manager.id} value={manager.name}>
                    {manager.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditUser}>
            Edit User
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertMessage && <Alert variant={alertVariant}>{alertMessage}</Alert>}
          <p>
            Are you sure you want to delete the Intern{" "}
            <strong>{selectedUserName}</strong>?
          </p>
          <div className={styles.dFlexJustifyContentEnd}>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
              className={styles.me2}
            >
              No
            </Button>
            <Button variant="danger" onClick={handleDeleteUser}>
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MyInterns;
