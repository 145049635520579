import React from 'react';
import ReactPlayer from 'react-player';
import styles from './Tutorial.module.css';
import CustomNavbar from "../CustomNavbar/CustomNavbar"

function Tutorial() {
    return (

        <>
            <CustomNavbar />
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div className={styles.videoWrapper}>
                <ReactPlayer
                    url="https://youtu.be/L1xBGxoBhDg"
                    controls={true}
                    className={styles.videoPlayer}
                />
            </div></>
    );
}

export default Tutorial;
