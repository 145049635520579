import React, { useEffect, useState } from "react";
import axios from "../axiosconfig/axiosconfig";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader"; // Import the SkeletonLoader
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function AdminProfile() {
  const [profile, setProfile] = useState(null);
  const [editableProfile, setEditableProfile] = useState({});
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const fetchProfile = async () => {
    try {
      const response = await axios.get("/admin/admin/profile");
      setProfile(response.data);
      setEditableProfile({ ...response.data, password: "" }); // Initialize password as empty
    } catch (error) {
      setError("There was an error fetching the profile data!");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableProfile({ ...editableProfile, [name]: value });
  };

  const handleSave = async () => {
    try {
      const profileToSave = { ...editableProfile };
      if (profileToSave.password === "") {
        delete profileToSave.password; // Do not send empty password to the server
      }
      const response = await axios.put("/admin/admin/profile", profileToSave);
      console.log(response.data);
      setIsEditing(false);
      fetchProfile(); // Refresh profile data after saving
    } catch (error) {
      setError("There was an error saving the profile data!");
      console.error(error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    );
  }

  if (!profile) {
    return <SkeletonLoader />; // Use the SkeletonLoader when profile is being fetched
  }

  return (
    <>
      <CustomNavbar />
      <div className="container mt-5 profile-container">
        <div className="card profile-card">
          <div className="card-body">
            {isEditing ? (
              <>
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={editableProfile.name}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Phone:</label>
                  <input
                    type="text"
                    name="phone"
                    value={editableProfile.phone}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Password:</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={editableProfile.password || ""}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Enter new password"
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <button onClick={handleSave} className="btn btn-primary">
                  Save
                </button>
              </>
            ) : (
              <>
                <h5 className="card-title">{profile.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {profile.designation}
                </h6>
                <p className="card-text">
                  <strong>Email:</strong> {profile.email || "N/A"}
                  <br />
                  <strong>Phone:</strong> {profile.phone || "N/A"}
                  <br />
                  <strong>Role:</strong>{" "}
                  {profile.role.charAt(0).toUpperCase() +
                    profile.role.slice(1) || "N/A"}
                  <br />
                </p>
                <button
                  onClick={() => setIsEditing(true)}
                  className="btn btn-primary"
                >
                  Edit
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
