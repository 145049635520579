import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomNav from "../CustomNavbar/CustomNavbar";
import SkeletonsLoader from "../SkeletonLoader/SkeletonLoader"; // Import the SkeletonLoader component
import styles from "./Admin.module.css";
import axios from "../axiosconfig/axiosconfig"; // Use the configured axios instance

function Admin() {
  const navigate = useNavigate();
  const [departmentCounts, setDepartmentCounts] = useState({});
  const [rolesCounts, setRolesCounts] = useState({});
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    const fetchDepartmentCounts = async () => {
      try {
        const response = await axios.get("/departments/count");
        setDepartmentCounts(response.data);
      } catch (error) {
        console.error("Error fetching department counts:", error);
      }
    };

    const fetchRolesCounts = async () => {
      try {
        const response = await axios.get("/users/roles");
        setRolesCounts(response.data);
      } catch (error) {
        console.error("Error fetching roles counts:", error);
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchDepartmentCounts(), fetchRolesCounts()]);
      setLoading(false); // Set loading to false after data is fetched
    };

    fetchData();
  }, []);

  const handleCardClick = (route) => {
    navigate(route);
  };

  const formatDepartmentName = (department) => {
    switch (department) {
      case "adminfacilitiesmaintenance":
        return "Admin Facilities Maintenance";
      case "financeaccounting":
        return "Finance Accounting";
      case "operations":
        return "Operations";
      case "peoplecx":
        return "People CX";
      case "procurementstores":
        return "Procurement Stores";
      case "protoservices":
        return "Proto Services";
      case "productengineering":
        return "Product Engineering";
      case "software":
        return "Software";
      case "admin":
        return "Admin";
      case "ceo":
        return "CEO";
      case "mechanical":
        return "Mechanical";
      case "manager":
        return "Manager";
      case "all":
        return "All";
      case "other":
        return "Other";
      default:
        return department;
    }
  };

  const formatRoles = (role) => {
    switch (role) {
      case "intern":
        return "Interns";
      case "manager":
        return "Managers";
      case "admin":
        return "Admins";

      default:
        return "Total Users";
    }
  };

  return (
    <>
      <CustomNav />
      <br />
      <br />
      <br />
      <br />
      <div className={`container admin-container mt-4 ${styles.main}`}>
        <h1 className="text-center mb-4">Dashboard 📊</h1>
        <div className="row">
          {loading
            ? Array(4)
                .fill()
                .map((_, index) => <SkeletonsLoader key={index} type="card" />) // Show skeletons while loading
            : Object.entries(rolesCounts)
                .sort((a, b) => b[1] - a[1]) // Sort in descending order
                .map(([role, count]) => (
                  <div key={role} className="col-md-3">
                    <div
                      className={styles.card}
                      onClick={() =>
                        handleCardClick(`/my${role.toLowerCase()}` + "s")
                      }
                    >
                      <div className={styles.cardBody}>
                        <h2 className={styles.cardTitle}>{count}</h2>
                        <p className={styles.cardText}>{formatRoles(role)}</p>
                      </div>
                    </div>
                  </div>
                ))}
        </div>
        <h2 className="text-center mb-4 mt-5">Departments 💼</h2>
        <div className="row">
          {loading
            ? Array(4)
                .fill()
                .map((_, index) => <SkeletonsLoader key={index} type="card" />) // Show skeletons while loading
            : Object.entries(departmentCounts)
                .sort((a, b) => b[1] - a[1]) // Sort in descending order
                .map(([department, count]) => (
                  <div key={department} className="col-md-3">
                    <div className={styles.card}>
                      <div className={styles.cardBody}>
                        <h2 className={styles.cardTitle}>{count}</h2>
                        <p className={styles.cardText}>
                          {formatDepartmentName(department)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
        </div>
      </div>
    </>
  );
}

export default Admin;
