import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../axiosconfig/axiosconfig"; // ensure you import your configured axios instance
import moment from "moment";
import { Container, Card } from "react-bootstrap";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import Skeleton from "react-loading-skeleton";
import HtmlParser from "react-html-parser"; // Import HtmlParser
import styles from "./Task.module.css"; // Import custom CSS for styling

function Task() {
  const { id } = useParams();
  const [task, setTask] = useState(null);

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const response = await axios.get(`/user/task/${id}`);
        console.log(response.data);

        // Assuming the response structure
        if (response.data.tasks && response.data.tasks.length > 0) {
          setTask(response.data.tasks[0]);
        } else {
          console.error("No tasks found in the response");
        }
      } catch (error) {
        console.error("Failed to fetch task:", error);
      }
    };
    fetchTask();
  }, [id]);

  if (!task) return <Skeleton height={400} />;

  return (
    <>
      <CustomNavbar />
      <br></br>
      <br></br>

      <br></br>
      <Container className={styles["task-details-container"]}>
        <Card className={styles["task-details-card"]}>
          <Card.Body>
            <Card.Text className={styles["task-details-completed"]}>
              <small className="text-muted">
                Completed: {task.isCompleted ? '✅' : '❌'}
              </small>
            </Card.Text>
            <Card.Title as="h1" className={styles["task-details-title"]}>
              {task.title}
            </Card.Title>
            <Card.Text className={styles["task-details-description"]}>
              {HtmlParser(task.description)}
            </Card.Text>
            <div className={styles["task-details-dates"]}>
              <Card.Text className={styles["task-details-date"]}>
                <small className="text-muted">
                  Created at: {moment(task.createdAt).format("LLL")}
                </small>
              </Card.Text>
              <Card.Text className={styles["task-details-date"]}>
                <small className="text-muted">
                  Start Date: {moment(task.startDate).format("LLL")}
                </small>
              </Card.Text>
              <Card.Text className={styles["task-details-date"]}>
                <small className="text-muted">
                  End Date: {moment(task.endDate).format("LLL")}
                </small>
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
      </Container>

    </>
  );
}

export default Task;
