import React, { useEffect, useState } from "react";
import axios from "../axiosconfig/axiosconfig";
import { useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import moment from "moment";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
function Calender() {
  const { id } = useParams();
  const [submittedFridays, setSubmittedFridays] = useState([]);
  const [internName, setInternName] = useState("");

  useEffect(() => {
    console.log("ID from params:", id);

    const fetchData = async () => {
      try {
        const response = await axios.get(`/admin/admin/submitted/${id}`);
        console.log(response.data);
        setSubmittedFridays(response.data.submittedFridays || []);
        setInternName(response.data.intern || "");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (id) {
      fetchData();
    } else {
      console.error("ID is undefined");
    }
  }, [id]);

  const allFridays = [
    "2024-06-14",
    "2024-06-21",
    "2024-06-28",
    "2024-07-05",
    "2024-07-12",
    "2024-07-19",
    "2024-07-26",
    "2024-08-02",
    "2024-08-09",
    "2024-08-16",
    "2024-08-23",
    "2024-08-30",
    "2024-09-06",
    "2024-09-13",
    "2024-09-20",
    "2024-09-27",
    "2024-10-04",
    "2024-10-11",
    "2024-10-18",
    "2024-10-25",
    "2024-11-01",
    "2024-11-08",
    "2024-11-15",
    "2024-11-22",
    "2024-11-29",
    "2024-12-06",
    "2024-12-13",
    "2024-12-20",
    "2024-12-27",
  ];

  const formatDate = (dateString) => {
    return moment(dateString).format("Do MMMM YYYY");
  };

  const formattedFridays = allFridays.map((date) => formatDate(date));

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
    },
    plotOptions: {
      line: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: formattedFridays,
    },
    yaxis: {
      title: {
        text: "Dates",
      },
      labels: {
        formatter: function (value) {
          return value === 1 ? "Submitted" : " ";
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#00E396", "#FF4560"],
  };

  const chartSeries = [
    {
      name: "Submitted",
      data: allFridays.map((date) => (submittedFridays.includes(date) ? 1 : 0)),
    },
  ];

  return (
    <>
      <CustomNavbar />
      <div>
        <h1>{internName}</h1>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={350}
        />
      </div>
    </>
  );
}

export default Calender;
