import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomNav from "../CustomNavbar/CustomNavbar";
import SkeletonsLoader from "../SkeletonLoader/SkeletonLoader";
import Card from "../Card/Card";
import styles from "./Manager.module.css";
import axios from "../axiosconfig/axiosconfig";

function Manager() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const name = localStorage.getItem('name');
                if (name) {
                    const encodedName = encodeURIComponent(name);
                    const response = await axios.get(`/manager/${encodedName}`);
                    setData(response.data);
                    setLoading(false);
                } else {
                    console.error("No name found in local storage");
                    setLoading(false);
                }
            } catch (error) {
                console.error("There was an error making the request:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCardClick = (id) => {
        navigate(`/manager/task/${id}`);
    };

    return (
        <>
            <CustomNav />
            <br />
            <br />
            <br />
            <br />
            <div className={`container admin-container mt-4 ${styles.main}`}>
                <h1 className="text-center mb-4">Dashboard 📊</h1>
                <div className="row">
                    {loading
                        ? Array(4)
                            .fill()
                            .map((_, index) => (
                                <div key={index} className="col-md-3">
                                    <SkeletonsLoader type="card" />
                                </div>
                            ))
                        : data.map((item) => (
                            <div key={item._id} className="col-md-6">
                                <Card
                                    name={item.name}
                                    phone={item.phone}
                                    email={item.email}
                                    designations={item.designations}
                                    department={item.department = item.department.charAt(0).toUpperCase() + item.department.slice(1)}
                                    onClick={() => handleCardClick(item._id)}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
}

export default Manager;
