import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <h1>404 - Not Found</h1>
            <Button variant="primary" onClick={() => navigate('/')}>Go to Landing Page</Button>
        </Container>
    );
};

export default NotFound;