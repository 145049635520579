import Landing from "../pages/Landing/Landing";
import Login from "../pages/Login/Login";
import AdminLogin from "../pages/AdminLogin/AdminLogin";
import ForgetPassword from "../pages/Forget/ForgetPassword";
export const publicRoutes = [
  {
    name: "Landing",
    path: "/",
    element: <Landing />,
  },
  {
    name: "Login",
    path: "/login",
    element: <Login />,
  },
  {
    name: "Admin Login",
    path: "/admin-login",
    element: <AdminLogin />,
  },
  {
    name: "Forget Password",
    path: "/forget-password",
    element: <ForgetPassword />,
  },
];
