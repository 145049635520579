// Card.js
import React from "react";
import PropTypes from "prop-types";
import styles from "./Card.module.css";

function Card({ name, phone, email, designations, department, onClick }) {
    return (
        <div className={styles.card} onClick={onClick}>
            <h4 className={styles.cardTitle}>{name}</h4>
            <p className={styles.cardText}><strong>Phone:</strong> {phone}</p>
            <p className={styles.cardText}><strong>Email:</strong> {email}</p>
            <p className={styles.cardText}><strong>Designations:</strong> {designations}</p>
            <p className={styles.cardText}><strong>Department:</strong> {department}</p>

        </div>
    );
}

Card.propTypes = {
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    designations: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Card;


