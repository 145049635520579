import React, { useEffect, useState } from "react";
import axios from "../axiosconfig/axiosconfig.js";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ConfettiComponent from "../ConfettiComponent/ConfettiComponent.js";
import CustomNavbar from "../CustomNavbar/CustomNavbar.js";
// import "./List.css"

function List() {
  const [interns, setInterns] = useState([]);
  const [filteredInterns, setFilteredInterns] = useState([]); // State for filtered interns
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentIntern, setCurrentIntern] = useState({});
  const [confetti, setConfetti] = useState(false);

  const [newIntern, setNewIntern] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "intern",
    designation: "",
    joiningDate: "",
  });

  const fetchInterns = async () => {
    try {
      const response = await axios.get("/admin/admin/intern");
      const sortedInterns = response.data.sort(
        (a, b) => new Date(b.joiningDate) - new Date(a.joiningDate)
      );
      setInterns(sortedInterns);
      setFilteredInterns(sortedInterns);
    } catch (error) {
      console.error("Error fetching interns:", error);
    }
  };

  useEffect(() => {
    fetchInterns();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date";

    const today = new Date();
    const timeDiff = today - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return `${daysDiff + 1} Days`;
  };

  const formatDate1 = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date";

    const options = {
      timeZone: "Asia/Kolkata",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedDateParts = date
      .toLocaleDateString("en-GB", options)
      .split(" ");

    const day = parseInt(formattedDateParts[0]);
    const month = formattedDateParts[1];
    const year = formattedDateParts[2];

    const getOrdinal = (n) => {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };

    const formattedDate = `${getOrdinal(day)} ${month} ${year}`;

    const today = new Date();
    const todayInIST = new Date(
      today.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    const timeDiff = todayInIST - date;
    // eslint-disable-next-line
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return `${formattedDate}`;
  };

  const triggerConfetti = () => {
    setConfetti(true);
    setTimeout(() => setConfetti(false), 3000);
  };

  const handleAddIntern = async () => {
    try {
      await axios.post("/admin/admin/intern", newIntern);
      fetchInterns();
      setShowAddModal(false);
      triggerConfetti();
    } catch (error) {
      console.error("Error adding intern:", error);
    }
  };

  const handleEditIntern = async () => {
    try {
      await axios.put(
        `/admin/admin/interns/${currentIntern._id}`,
        currentIntern
      );
      fetchInterns();
      setShowEditModal(false);
      triggerConfetti();
    } catch (error) {
      console.error("Error editing intern:", error);
    }
  };

  const handleDeleteIntern = async () => {
    try {
      await axios.delete(`/admin/admin/intern/${currentIntern._id}`);
      fetchInterns();
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting intern:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = interns.filter((intern) =>
      intern.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredInterns(filtered);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sorted = [...filteredInterns].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredInterns(sorted);
  };

  return (
    <>
      <CustomNavbar />
      <br></br>
      <br></br>
      <br></br>
      <div className="container">
        <h1>Interns List</h1>
        <Button className="btn-primary" onClick={() => setShowAddModal(true)}>
          Add Intern
        </Button>
        <br></br>
        <br></br>
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearch}
          className="form-control mb-3"
        />
        <div className="table-responsive">
          <Table striped bordered hover className="table">
            <thead>
              <tr>
                <th>S.NO</th>
                <th onClick={() => handleSort("name")}>Name</th>
                <th onClick={() => handleSort("designation")}>Designation</th>
                <th>Email</th>
                <th>Phone</th>
                <th onClick={() => handleSort("joiningDate")}>Joining Date</th>
                <th>Total Days</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredInterns.map((intern, index) => (
                <tr key={intern._id}>
                  <td>{index + 1}</td>
                  <td>{intern.name}</td>
                  <td>{intern.designation}</td>
                  <td>{intern.email}</td>
                  <td>{intern.phone}</td>
                  <td>{formatDate1(intern.joiningDate)}</td>
                  <td>{formatDate(intern.joiningDate)}</td>
                  <td>
                    <Button
                      className="btn-warning"
                      onClick={() => {
                        setCurrentIntern(intern);
                        setShowEditModal(true);
                      }}
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      className="btn-danger"
                      onClick={() => {
                        setCurrentIntern(intern);
                        setShowDeleteModal(true);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Add Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Intern</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={newIntern.name}
                onChange={(e) =>
                  setNewIntern({ ...newIntern, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={newIntern.email}
                onChange={(e) =>
                  setNewIntern({ ...newIntern, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone"
                value={newIntern.phone}
                onChange={(e) =>
                  setNewIntern({ ...newIntern, phone: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                value={newIntern.password}
                onChange={(e) =>
                  setNewIntern({ ...newIntern, password: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formDesignation">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter designation"
                value={newIntern.designation}
                onChange={(e) =>
                  setNewIntern({ ...newIntern, designation: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formJoiningDate">
              <Form.Label>Joining Date</Form.Label>
              <Form.Control
                type="date"
                value={newIntern.joiningDate}
                onChange={(e) =>
                  setNewIntern({ ...newIntern, joiningDate: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddIntern}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Intern</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={currentIntern.name}
                onChange={(e) =>
                  setCurrentIntern({ ...currentIntern, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={currentIntern.email}
                onChange={(e) =>
                  setCurrentIntern({ ...currentIntern, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone"
                value={currentIntern.phone}
                onChange={(e) =>
                  setCurrentIntern({ ...currentIntern, phone: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formDesignation">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter designation"
                value={currentIntern.designation}
                onChange={(e) =>
                  setCurrentIntern({
                    ...currentIntern,
                    designation: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formJoiningDate">
              <Form.Label>Joining Date</Form.Label>
              <Form.Control
                type="date"
                value={currentIntern.joiningDate}
                onChange={(e) =>
                  setCurrentIntern({
                    ...currentIntern,
                    joiningDate: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditIntern}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this Intern?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Intern Name: {currentIntern.name}</p>
          <p>Email: {currentIntern.email}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteIntern}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfettiComponent run={confetti} />
    </>
  );
}

export default List;
