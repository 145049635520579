import React, { useEffect, useState } from "react";
import axios from "../axiosconfig/axiosconfig";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import styles from "./Profile.module.css";

export default function Profile() {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [managers, setManagers] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [editableProfile, setEditableProfile] = useState({
    password: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fetchProfile = async () => {
    try {
      const response = await axios.get("/users");
      setProfile(response.data);
      setEditableProfile({
        ...response.data,
        password: "",
      });
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const response = await axios.get("/managers");
        setManagers(response.data);
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    };

    fetchManagers();
  }, []);

  const handleSaveProfile = async (e) => {
    e.preventDefault();

    let updatedFields = {};
    Object.keys(editableProfile).forEach((key) => {
      if (editableProfile[key] !== profile[key]) {
        updatedFields[key] = editableProfile[key];
      }
    });

    if (!updatedFields.password) {
      delete updatedFields.password;
    }

    try {
      let data = await axios.put("/updateusers", updatedFields);
      console.log(data.data);

      fetchProfile();
      setAlertMessage("Profile updated successfully!");
      setAlertType("success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);

      setError(error.response?.data?.message || "An error occurred");
      setAlertMessage("Failed to update profile.");
      setAlertType("danger");
    }
  };

  const handleCancel = () => {
    setEditableProfile({ ...profile, password: "" });
  };

  if (!profile) {
    return <SkeletonLoader />;
  }

  return (
    <>
      <CustomNavbar />
      <br></br>
      <br></br>

      <br></br>

      <br></br>

      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <Form
              className={styles.adminEditProfileForm}
              onSubmit={handleSaveProfile}
            >
              <div className={styles.alertContainer}>
                {alertMessage && (
                  <Alert variant={alertType}>{alertMessage}</Alert>
                )}
              </div>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={editableProfile.name || ""}
                  onChange={(e) =>
                    setEditableProfile({
                      ...editableProfile,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  value={editableProfile.phone || ""}
                  onChange={(e) =>
                    setEditableProfile({
                      ...editableProfile,
                      phone: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formPassword" className="mt-3">
                <Form.Label>Password</Form.Label>
                <div className={styles.passwordContainer}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={editableProfile.password || ""}
                    onChange={(e) =>
                      setEditableProfile({
                        ...editableProfile,
                        password: e.target.value,
                      })
                    }
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={togglePasswordVisibility}
                    className="ms-2"
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </Button>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  readOnly
                  value={
                    editableProfile.role
                      ? editableProfile.role.charAt(0).toUpperCase() +
                        editableProfile.role.slice(1)
                      : ""
                  }
                >
                  <option>{editableProfile.role}</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  readOnly
                  value={editableProfile.email || ""}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  as="select"
                  readOnly
                  value={editableProfile.designations || ""}
                >
                  <option>{editableProfile.designations}</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Department</Form.Label>
                <Form.Control
                  as="select"
                  readOnly
                  value={editableProfile.department || ""}
                >
                  <option>{editableProfile.department}</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Joining Date</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={
                    new Date(editableProfile.joiningDate).toLocaleDateString(
                      "en-CA"
                    ) || ""
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Reporting Manager</Form.Label>
                <Form.Control
                  as="select"
                  readOnly
                  value={editableProfile.reportingManager || ""}
                >
                  <option>{editableProfile.reportingManager}</option>
                </Form.Control>
              </Form.Group>
              <Button
                style={{ margin: "10px" }}
                variant="primary"
                type="submit"
              >
                Update
              </Button>
              <Button
                variant="secondary"
                onClick={handleCancel}
                className="ml-2"
              >
                Cancel
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
