// axiosConfig.js
import axios from "axios";
import Cookies from "js-cookie";
import config from "../../config.json";

const URL = config.URL;
console.log(URL);

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const sessionId = Cookies.get("session_id");

    if (sessionId) {
      config.headers["Authorization"] = `Bearer ${sessionId}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
