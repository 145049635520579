// SkeletonLoader.js
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonsLoader = ({ type }) => {
    if (type === 'card') {
        return (
            <div className="col-md-3">
                <div className="card text-center shadow-lg p-3 mb-5 bg-white rounded">
                    <div className="card-body">
                        <h5 className="card-title"><Skeleton width={50} /></h5>
                        <p className="card-text"><Skeleton width={100} /></p>
                    </div>
                </div>
            </div>
        );
    }

    if (type === 'table') {
        return (
            <tr>
                <td><Skeleton width={100} /></td>
                <td><Skeleton width={50} /></td>
            </tr>
        );
    }

    return null;
};

export default SkeletonsLoader;
